import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import SimpleAccordion from 'components/accordion/simple'
import ClickFadeCarousel from 'components/carousel/clickFade'
import PosterCard from 'components/card/poster'
import NewsCard from 'components/card/news'
import NumberStepper from 'components/stepper/number'
import BlueHeaderTable from 'components/table/blueHeader'
import ZoomGallery from 'components/gallery/zoom'
import I18nImage from 'components/gallery/i18nImage'

import {
  createStreamSteps,
  comparisonTable,
  industryApplyList,
  llhlsAdvantages
} from '/static/data/lowLatencyList.static'
import streamFunctions from '/static/data/feature.static'
import newsList from '/static/data/newsList.static'

const LowLatencyPage = () => {
  const { t } = useTranslation()
  const [stepIndex, setStepIndex] = useState(0)
  const [accordionIndex, setAccordionIndex] = useState(0)
  const [sdkRecord, setSdkRecord] = useState({})
  const sdkRecordList = [
    {
      name: t('lowLatency.section2.list1.item1.title'),
      value: sdkRecord.current_latency > 0 ? Math.round(sdkRecord.current_latency * 100) / 100 : 0
    }
  ]
  const clickStep = (index) => {
    setStepIndex(index)
  }
  const clickAccordion = (index) => {
    setAccordionIndex(index)
  }
  const CustomDetailSlot = (
    <div className="flex flex-col lg:flex-row gap-3 my-3">
      <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
        {t('button.freeTrial.0')}
      </Link>
      <Link to="/lp/get-a-demo/" className="btn btn-bold btn-ghost btn-orange inline-block" style={{ width: 208 }}>
        {t('button.getDemo.0')}
      </Link>
    </div>
  )

  if (typeof window !== 'undefined') {
    const targetOrigin = window.location.origin

    window.addEventListener('message', function (e) {
      if (e.origin === targetOrigin) {
        const record = e.data

        if (record?.event === 'pause') {
          setSdkRecord({ current_latency: 0 })
        } else if (record?.current_latency) {
          setSdkRecord(record)
        }
      }
    })
  }

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('lowLatency.meta.title'),
            metaDescription: t('lowLatency.meta.description'),
            metaUrl: 'https://www.mlytics.com/video-stream/low-latency-live-streaming/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2022/06/logo_Mlytics_regular.png'
            },
            article: true
          }}
        />
        <section className="container flex flex-col md:flex-row mb-20 mt-10">
          <div className="flex-1 my-10">
            <div className="pr-10">
              <h1 className="leading-tight text-primary-600">
                <Trans i18nKey="lowLatency.section1.title">
                  <span
                    className="text-secondary-600"
                    dangerouslySetInnerHTML={{
                      __html: t('lowLatency.section1.titleText')
                    }}
                  />
                </Trans>
              </h1>
              <p className="py-7 text-gray-500">{t('lowLatency.section1.description')}</p>
              <div className="flex flex-col lg:flex-row gap-3 my-1">
                <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                  {t('button.freeTrial.0')}
                </Link>
                <Link
                  to="/lp/get-a-demo/"
                  className="btn btn-bold btn-ghost btn-orange inline-block"
                  style={{ width: 208 }}
                >
                  {t('button.getDemo.0')}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-1 items-center overflow-hidden">
            <ZoomGallery>
              <I18nImage
                src="https://static.mlytics.com/images/website/low_latency_hls_technology.png"
                alt={t('lowLatency.section1.imageAlt')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </ZoomGallery>
          </div>
        </section>
        <section className="container py-20">
          <h2 className="font-bold text-center text-5xl text-primary-600">{t('lowLatency.section2.title')}</h2>
          <div className="flex flex-col mt-10 md:flex-row">
            <div className="w-full lg:w-3/5" style={{ maxWidth: 600 }}>
              <iframe src="/videos/latency.html" height="338" width="100%" />
            </div>
            <div className="flex flex-col justify-between w-full lg:w-2/5 px-4 lg:ml-10">
              <div>
                {sdkRecordList.map((item) => (
                  <div key={item.name} className="flex justify-between py-4">
                    <span className="font-bold text-xl md:text-2xl text-primary-500">{item.name}</span>
                    <span className="font-black ml-5 text-2xl md:text-3xl text-gray-600">
                      {item.value ? item.value.toFixed(2) : ' - '}s
                    </span>
                  </div>
                ))}
                <p className="py-7 text-gray-700">{t('lowLatency.section2.list1.hint')}</p>
              </div>
              <div className="flex gap-3 text-center">
                <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                  {t('button.freeTrial.0')}
                </Link>
                <Link
                  to="/lp/get-a-demo/"
                  className="btn btn-bold btn-ghost btn-orange inline-block"
                  style={{ width: 208 }}
                >
                  {t('button.getDemo.0')}
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section className="container mb-32 mt-10">
          <h2 className="font-bold text-center text-5xl text-primary-600">{t('lowLatency.section3.title')}</h2>
          <p className="mb-16 mt-10 text-gray-500">{t('lowLatency.section3.description1')}</p>
          <div className="flex justify-center mb-20">
            <ZoomGallery>
              <I18nImage
                src="https://static.mlytics.com/images/website/how_ll_hls_reduces_lantency.png"
                alt={t('lowLatency.section3.imageAlt')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </ZoomGallery>
          </div>
          <ol className="grid grid-cols-1 xl:grid-cols-3 gap-12 list-decimal">
            {llhlsAdvantages.map((item) => (
              <React.Fragment key={item.title}>
                <li className="font-black mb-2 ml-7 text-2xl">
                  <h3 className="font-black mb-2">{t(item.title)}</h3>
                  <div className="font-normal text-base text-gray-500">{t(item.content)}</div>
                </li>
              </React.Fragment>
            ))}
          </ol>
        </section>
        <section className="container mb-20 mt-10">
          <h2 className="font-bold text-center text-5xl text-primary-600">{t('lowLatency.section4.title')}</h2>
          <div className="flex justify-center">
            <p className="font-normal my-8 text-base text-center text-gray-500 w-4/5">
              {t('lowLatency.section4.description')}
            </p>
          </div>
          <div className="flex flex-col md:flex-row mt-16">
            <div className="flex items-center justify-center md:justify-end w-full md:w-1/3">
              <div>
                <NumberStepper
                  clickHandler={(index) => clickStep(index)}
                  steps={createStreamSteps}
                  currentStepIndex={stepIndex}
                  orientation="vertical"
                  connectorType="dotted"
                />
              </div>
            </div>
            <div className="flex-1 overflow-hidden w-full md:w-2/3">
              <ClickFadeCarousel
                autoplay
                activeIndex={stepIndex}
                slides={createStreamSteps}
                beforeSlideChange={(index) => setStepIndex(index)}
                withDots
              />
            </div>
          </div>
          <section className="my-20">
            <div className="container flex flex-col md:flex-row-reverse">
              <div className="flex-1">
                <h3 className="text-gray-800 text-3xl">{t('lowLatency.section5.title')}</h3>
                <p className="py-5 text-gray-500">{t('lowLatency.section5.description')}</p>
              </div>
              <div className="flex-1 md:px-8">
                <ZoomGallery>
                  <I18nImage
                    src="https://static.mlytics.com/images/website/ll_hls_backward_support.png"
                    alt={t('lowLatency.section5.imageAlt')}
                    style={{ height: 'auto', maxWidth: '100%' }}
                  />
                </ZoomGallery>
              </div>
            </div>
          </section>
          <section className="my-20">
            <div className="container flex flex-col md:flex-row">
              <div className="flex-1 md:px-8 pb-5">
                <h3 className="text-gray-800 text-3xl">{t('lowLatency.section6.title')}</h3>
                <p className="py-5 text-gray-500">{t('lowLatency.section6.description')}</p>
              </div>
              <div className="flex-1 md:px-8">
                <ZoomGallery>
                  <I18nImage
                    src="https://static.mlytics.com/images/website/rtmp_for_live_broadcasts_and_ll_hls.png"
                    alt={t('lowLatency.section6.imageAlt')}
                    style={{ height: 'auto', maxWidth: '100%' }}
                  />
                </ZoomGallery>
              </div>
            </div>
          </section>
          <section className="my-20">
            <div className="container flex flex-col md:flex-row-reverse">
              <div className="flex-1">
                <h3 className="text-gray-800 text-3xl">
                  <Trans i18nKey="lowLatency.section7.title">
                    <br />
                  </Trans>
                </h3>
                <p className="py-5 text-gray-500">{t('lowLatency.section7.description')}</p>
              </div>
              <div className="flex-1 md:px-8">
                <ZoomGallery>
                  <I18nImage
                    src="https://static.mlytics.com/images/website/measure_low_latency_success.png"
                    alt={t('lowLatency.section7.imageAlt')}
                    style={{ height: 'auto', maxWidth: '100%' }}
                  />
                </ZoomGallery>
              </div>
            </div>
          </section>
        </section>
        <section className="bg-primary-50 py-20">
          <div className="container">
            <h2 className="font-bold text-center text-5xl text-primary-600">{t('lowLatency.section8.title')}</h2>
            <div className="flex flex-col items-center my-14">
              <BlueHeaderTable tableBody={comparisonTable.body} tableHeads={comparisonTable.head} />
              <p className="mt-14 text-gray-500">{t('lowLatency.section8.description')}</p>
            </div>
          </div>
        </section>
        <section className="container px-4 md:px-10 py-20">
          <h2 className="font-bold text-center text-5xl text-primary-600">{t('lowLatency.section9.title')}</h2>
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 md:px-8 overflow-hidden">
              <ClickFadeCarousel activeIndex={accordionIndex} slides={industryApplyList} />
            </div>
            <div className="flex flex-1 justify-center mt-14">
              <div className="flex-1">
                <SimpleAccordion
                  clickHandler={(index) => clickAccordion(index)}
                  defaultExpandIndex={0}
                  detailSlot={CustomDetailSlot}
                  list={industryApplyList}
                  iconStyle="plus"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-gradient-blue px-4 md:px-10 py-16">
          <h2 className="text-center text-white text-5xl">{t('heading.learnMoreAboutStream')}</h2>
          <div className="flex flex-col md:flex-row justify-center pt-10">
            {streamFunctions.map((item, index) => {
              return index === 4 ? null : (
                <PosterCard
                  className="basis-0 mx-3 mb-3 md:mb-0"
                  key={index}
                  iconName={item.iconName}
                  title={t(item.title)}
                  paragraph={t(item.paragraph)}
                  anchorHref={item.anchorHref}
                  anchorText={t(item.anchorText)}
                />
              )
            })}
          </div>
          <div className="flex flex-col lg:flex-row gap-3 items-center justify-center mt-10">
            <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
              {t('button.freeTrial.0')}
            </Link>
            <Link
              to="/lp/get-a-demo/"
              className="btn btn-bold bg-white border-white text-secondary-600 inline-block"
              style={{ width: 208 }}
            >
              {t('button.getDemo.0')}
            </Link>
          </div>
        </section>
        <section className="hidden xl:block px-10 py-24">
          <h2 className="font-black text-center text-4.5xl text-primary-600">{t('heading.productPlatform')}</h2>
          <div className="flex justify-center mt-16">
            <img
              src="https://static.mlytics.com/images/website/mlytics_platform.svg"
              alt={t('imageAlt.platform')}
              style={{ height: 'auto', maxWidth: '1260px' }}
            />
          </div>
        </section>
        <section className="px-4 xl:px-10 py-10">
          <h2 className="text-center text-primary-600 text-6xl">{t('heading.latestNews')}</h2>
          <div className="flex flex-col xl:flex-row items-center justify-center mt-10 mb-20">
            {newsList.map((item, index) => (
              <NewsCard
                key={index}
                className="w-full xl:mx-2 mb-3"
                flag={t(item.flag)}
                title={t(item.title)}
                subtitle={t(item.subtitle)}
                paragraph={t(item.paragraph)}
                anchorHref={item.anchorHref}
                imageSrc={item.imageSrc}
                imageAlt={t(item.imageAlt)}
              />
            ))}
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default LowLatencyPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
