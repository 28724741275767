import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from '@emotion/styled'

import { Stepper, Step, StepLabel, stepLabelClasses, StepConnector, stepConnectorClasses } from '@mui/material'

const ColorStepperConnector = styled(StepConnector)(({ orientation, connectorType }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ebebeb'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ebebeb'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderLeftWidth: orientation === 'vertical' ? 3 : 0,
    borderStyle: connectorType,
    height: orientation === 'vertical' ? 60 : 0,
    width: orientation === 'vertical' ? 0 : 60,
    left: orientation === 'vertical' ? 16 : 0,
    position: 'relative'
  }
}))
const CustomStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.label}`]: {
    cursor: 'pointer',
    fontSize: 24,
    fontWeight: 400,
    [`&.${stepLabelClasses.active}`]: {
      color: '#21838d',
      fontWeight: 400
    },
    [`&.${stepLabelClasses.completed}`]: {
      color: '#cacaca',
      fontWeight: 400
    }
  }
}))
const CustomStepIcon = ({ active, completed, icon }) => {
  return <CustomStepIconRoot ownerState={{ active, completed }}>{icon}</CustomStepIconRoot>
}
const CustomStepIconRoot = styled('div')(({ ownerState }) => ({
  alignItems: 'center',
  backgroundColor: '#fff',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  borderRadius: '50%',
  color: '#3a3a3a',
  cursor: 'pointer',
  display: 'flex',
  fontSize: 32,
  fontWeight: 400,
  height: 60,
  justifyContent: 'center',
  width: 60,
  zIndex: 1,
  ...(ownerState.active && {
    backgroundColor: '#21838d',
    color: '#fff'
  }),
  ...(ownerState.completed && {
    color: '#cacaca'
  })
}))

const Simple = ({
  className = '',
  clickHandler = () => {},
  currentStepIndex,
  steps,
  orientation = 'horizontal',
  connectorType = 'solid'
}) => {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState(0)
  const clickStep = (index) => {
    setActiveStep(index)
    clickHandler(index)
  }

  useEffect(() => {
    setActiveStep(currentStepIndex)
  }, [currentStepIndex])

  return (
    <Stepper
      className={className}
      activeStep={activeStep}
      connector={<ColorStepperConnector orientation={orientation} connectorType={connectorType} />}
      orientation={orientation}
    >
      {steps.map((label, index) => (
        <Step key={index} onClick={() => clickStep(index)}>
          <CustomStepLabel StepIconComponent={CustomStepIcon}>{t(label.text)}</CustomStepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number
}
Simple.propTypes = {
  className: PropTypes.string,
  clickHandler: PropTypes.func,
  currentStepIndex: PropTypes.number,
  steps: PropTypes.array,
  orientation: PropTypes.string,
  connectorType: PropTypes.string
}

export default Simple
