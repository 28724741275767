const streamFunctions = [
  {
    iconName: 'query_stats_outlined',
    title: 'videoStreamAnalytics.streamFunctions.item1.title',
    paragraph: 'videoStreamAnalytics.streamFunctions.item1.paragraph',
    anchorHref: '/video-stream/audience-analytics/',
    anchorText: 'videoStreamAnalytics.streamFunctions.item1.anchorText'
  },
  {
    iconName: 'hub_outlined',
    title: 'videoStreamAnalytics.streamFunctions.item2.title',
    paragraph: 'videoStreamAnalytics.streamFunctions.item2.paragraph',
    anchorHref: '/video-stream/multi-cdn-management/',
    anchorText: 'videoStreamAnalytics.streamFunctions.item2.anchorText'
  },
  {
    iconName: 'alt_route_outlined',
    title: 'videoStreamAnalytics.streamFunctions.item3.title',
    paragraph: 'videoStreamAnalytics.streamFunctions.item3.paragraph',
    anchorHref: '/video-stream/instream-switching/',
    anchorText: 'videoStreamAnalytics.streamFunctions.item3.anchorText'
  },
  {
    iconName: 'leak_add_outlined',
    title: 'videoStreamAnalytics.streamFunctions.item4.title',
    paragraph: 'videoStreamAnalytics.streamFunctions.item4.paragraph',
    anchorHref: '/video-stream/hybrid-cdn-p2p/',
    anchorText: 'videoStreamAnalytics.streamFunctions.item4.anchorText'
  },
  {
    iconName: 'slow_motion_video_outlined',
    title: 'videoStreamAnalytics.streamFunctions.item5.title',
    paragraph: 'videoStreamAnalytics.streamFunctions.item5.paragraph',
    anchorHref: '/video-stream/low-latency-live-streaming/',
    anchorText: 'videoStreamAnalytics.streamFunctions.item5.anchorText'
  }
]

export default streamFunctions
