const newsList = [
  {
    flag: 'videoStreamAnalytics.newsList.item1.flag',
    imageSrc:
      'https://www.mlytics.com/wp-content/uploads/2023/03/Mlytics-Cloud-Cost-Optimization-Strategies-on-Google-Cloud.png',
    imageAlt: 'videoStreamAnalytics.newsList.item1.imageAlt',
    title: 'videoStreamAnalytics.newsList.item1.title',
    subtitle: 'videoStreamAnalytics.newsList.item1.subtitle',
    paragraph: 'videoStreamAnalytics.newsList.item1.paragraph',
    anchorHref: 'https://www.mlytics.com/blog/mlytics-cloud-cost-optimization-strategies-on-google-cloud/'
  },
  {
    flag: 'videoStreamAnalytics.newsList.item2.flag',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2023/03/Mlytics-AWS-Award-1-1024x686_2.png',
    imageAlt: 'videoStreamAnalytics.newsList.item2.imageAlt',
    title: 'videoStreamAnalytics.newsList.item2.title',
    subtitle: 'videoStreamAnalytics.newsList.item2.subtitle',
    paragraph: 'videoStreamAnalytics.newsList.item2.paragraph',
    anchorHref: 'https://www.mlytics.com/blog/mlytics-awarded-aws-rising-star-partner-of-the-year/'
  },
  {
    flag: 'videoStreamAnalytics.newsList.item3.flag',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2024/02/Case-study_Monetization.png',
    imageAlt: 'videoStreamAnalytics.newsList.item3.imageAlt',
    title: 'videoStreamAnalytics.newsList.item3.title',
    subtitle: 'videoStreamAnalytics.newsList.item3.subtitle',
    paragraph: 'videoStreamAnalytics.newsList.item3.paragraph',
    anchorHref: 'https://www.mlytics.com/blog/transforming-monetization-for-media-company-with-mlytics-2/'
  },
  {
    flag: 'videoStreamAnalytics.newsList.item4.flag',
    imageSrc: 'https://www.mlytics.com/wp-content/uploads/2023/03/release_1.png',
    imageAlt: 'videoStreamAnalytics.newsList.item4.imageAlt',
    title: 'videoStreamAnalytics.newsList.item4.title',
    subtitle: 'videoStreamAnalytics.newsList.item4.subtitle',
    paragraph: 'videoStreamAnalytics.newsList.item4.paragraph',
    anchorHref:
      'https://www.mlytics.com/blog/2023-enhancements-to-mlytics-platform-a-technical-overview-of-streamlined-ui-changes'
  }
]

export default newsList
