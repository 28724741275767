const createStreamSteps = [
  {
    text: 'lowLatency.section4.list1.step1.title',
    imageSrc: 'https://static.mlytics.com/images/website/step_1_create_a_stream.png',
    imageAlt: 'lowLatency.section4.list1.step1.imageAlt'
  },
  {
    text: 'lowLatency.section4.list1.step2.title',
    imageSrc: 'https://static.mlytics.com/images/website/step_2_ingest_to_mlytics.png',
    imageAlt: 'lowLatency.section4.list1.step2.imageAlt'
  },
  {
    text: 'lowLatency.section4.list1.step3.title',
    imageSrc: 'https://static.mlytics.com/images/website/step_3_stream_it_anywhere.png',
    imageAlt: 'lowLatency.section4.list1.step3.imageAlt'
  }
]
const comparisonTable = {
  head: [
    'lowLatency.section8.table1.header.column1',
    'lowLatency.section8.table1.header.column2',
    'lowLatency.section8.table1.header.column3',
    'lowLatency.section8.table1.header.column4'
  ],
  body: [
    {
      data: ['lowLatency.section8.table1.body.row1', 'checked', 'checked', 'checked']
    },
    {
      data: ['lowLatency.section8.table1.body.row2', 'checked', 'checked', 'checked']
    },
    {
      data: ['lowLatency.section8.table1.body.row3', 'checked', 'checked', 'warn'],
      tooltips: ['', '', '', 'lowLatency.section8.table1.tooltip.info1']
    },
    {
      data: ['lowLatency.section8.table1.body.row4', 'checked', null, 'checked']
    },
    {
      data: ['lowLatency.section8.table1.body.row5', 'checked', 'warn', null],
      tooltips: ['', '', 'lowLatency.section8.table1.tooltip.info2', '']
    },
    {
      data: ['lowLatency.section8.table1.body.row6', 'checked', null, null]
    }
  ]
}
const industryApplyList = [
  {
    title: 'lowLatency.section9.list1.item1.title',
    content: 'lowLatency.section9.list1.item1.content',
    imageSrc: 'https://static.mlytics.com/images/website/apply_low_latency_live_streaming_to_live_shopping.png',
    imageAlt: 'lowLatency.section9.list1.item1.title'
  },
  {
    title: 'lowLatency.section9.list1.item2.title',
    content: 'lowLatency.section9.list1.item2.content',
    imageSrc: 'https://static.mlytics.com/images/website/apply_low_latency_live_streaming_to_auctions.png',
    imageAlt: 'lowLatency.section9.list1.item2.title'
  },
  {
    title: 'lowLatency.section9.list1.item3.title',
    content: 'lowLatency.section9.list1.item3.content',
    imageSrc: 'https://static.mlytics.com/images/website/apply_low_latency_live_streaming_to_betting.png',
    imageAlt: 'lowLatency.section9.list1.item3.title'
  },
  {
    title: 'lowLatency.section9.list1.item4.title',
    content: 'lowLatency.section9.list1.item4.content',
    imageSrc: 'https://static.mlytics.com/images/website/apply_low_latency_live_streaming_to_live_sport_esport.png',
    imageAlt: 'lowLatency.section9.list1.item4.title'
  }
]
const llhlsAdvantages = [
  {
    title: 'lowLatency.section3.list1.item1.title',
    content: 'lowLatency.section3.list1.item1.content'
  },
  {
    title: 'lowLatency.section3.list1.item2.title',
    content: 'lowLatency.section3.list1.item2.content'
  },
  {
    title: 'lowLatency.section3.list1.item3.title',
    content: 'lowLatency.section3.list1.item3.content'
  }
]

export { createStreamSteps, comparisonTable, industryApplyList, llhlsAdvantages }
