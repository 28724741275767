import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { redirectTo } from 'utils'

const CardTitle = styled.div`
  display: -webkit-box;
  height: 5.25rem;
  line-height: 1.75rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`
const CardParagraph = styled.p`
  display: -webkit-box;
  height: 4.5rem;
  line-height: 1.5rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const News = ({
  className = '',
  flag,
  title,
  subtitle,
  paragraph,
  anchorHref,
  anchorText = 'Read more',
  imageSrc,
  imageAlt = 'news'
}) => {
  return (
    <div className={`flex flex-col rounded-lg shadow-lg ${className}`} style={{ minWidth: '295px', maxWidth: '350px' }}>
      <div className="bg-primary-600 py-2 font-bold rounded-t-lg text-center text-white text-xl">{flag}</div>
      <img src={imageSrc} alt={imageAlt} className="object-cover w-full" style={{ height: '180px' }} />
      <div className="flex flex-col flex-1 justify-between px-6 pt-4 pb-6">
        <div>
          <CardTitle className="text-primary-600 text-xl">
            <a href={anchorHref} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </CardTitle>
          <div className="pb-4 pt-3 text-primary-800 text-xs">{subtitle}</div>
          <CardParagraph>{paragraph}</CardParagraph>
        </div>
        <button onClick={redirectTo(anchorHref, '_blank')} className="block btn btn-ghost btn-green mt-2 w-full">
          {anchorText}
        </button>
      </div>
    </div>
  )
}

News.propTypes = {
  className: PropTypes.string,
  flag: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  paragraph: PropTypes.string,
  anchorHref: PropTypes.string,
  anchorText: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string
}

export default News
