import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Tooltip, tooltipClasses } from '@mui/material'

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3A3A3A',
    fontSize: 16
  }
}))

StyledTooltip.propTypes = {
  title: PropTypes.string
}

export default StyledTooltip
