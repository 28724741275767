import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import Icon from 'components/icon'
import Tooltip from 'components/tooltip'

const BlueHeader = ({ tableBody = [], tableHeads = [] }) => {
  const { t } = useTranslation()
  const contentMapping = (text, tooltip = null) => {
    let Element = text

    if (text === 'checked') {
      Element = <Icon className="text-blue-200" fontSize="20px" name="check_outlined" />
    } else if (text === 'warn') {
      Element = <Icon className="text-yellow-500" fontSize="20px" name="warning_amber_outlined" />
    }
    if (tooltip) {
      Element = (
        <Tooltip title={t(tooltip)}>
          <button>{Element}</button>
        </Tooltip>
      )
    }

    return Element
  }
  const tableCellClasses = {
    0: { root: 'bg-gray-800' },
    1: { root: 'bg-blue-300' },
    2: { root: 'bg-blue-200' },
    3: { root: 'bg-blue-100' }
  }
  const tableCellClassesMapping = (index) => {
    return tableCellClasses[index]
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {tableHeads.map((header, index) => (
              <TableCell key={index} align={index ? 'center' : 'left'} classes={tableCellClassesMapping(index)}>
                <span className="font-bold px-4 text-white text-2xl">{t(header)}</span>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.data.map((item, itemIndex) => (
                <TableCell
                  key={itemIndex}
                  align={itemIndex ? 'center' : 'left'}
                  classes={{ root: itemIndex ? 'border-r' : 'border-r border-r-gray-800' }}
                  sx={{ minWidth: 180 }}
                >
                  <span className="px-4 text-base">{contentMapping(t(item), row?.tooltips?.[itemIndex])}</span>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

BlueHeader.propTypes = {
  tableBody: PropTypes.array,
  tableHeads: PropTypes.array
}

export default BlueHeader
