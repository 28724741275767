import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import ZoomGallery from 'components/gallery/zoom'
import I18nImage from 'components/gallery/i18nImage'

const ClickFade = ({ autoplay = false, activeIndex, slides, beforeSlideChange = () => {}, withDots = false }) => {
  const { t } = useTranslation()

  const sliderRef = useRef(null)
  const settings = {
    fade: true,
    infinite: true,
    autoplay,
    autoSpeed: 4000,
    dots: withDots,
    dotsClass: withDots ? 'slick-dots slick-paging-container rounded' : undefined,
    slidesToShow: 1,
    swipe: false,
    beforeChange: (current, next) => {
      beforeSlideChange(next)
    }
  }

  useEffect(() => {
    sliderRef.current.slickGoTo(activeIndex)
  })

  return (
    <div style={{ width: '100%' }}>
      <Slider className="py-10" ref={sliderRef} {...settings}>
        {slides.map((item, index) => (
          <div className="text-center" key={index}>
            <ZoomGallery>
              <I18nImage src={item.imageSrc} alt={t(item.imageAlt)} className="mx-auto" height="304" width="516" />
            </ZoomGallery>
          </div>
        ))}
      </Slider>
    </div>
  )
}

ClickFade.propTypes = {
  autoplay: PropTypes.bool,
  activeIndex: PropTypes.number,
  beforeSlideChange: PropTypes.func,
  withDots: PropTypes.bool,
  slides: PropTypes.array
}

export default ClickFade
