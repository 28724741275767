import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Icon from 'components/icon'

const Poster = ({ className = '', iconName = '', title = '', paragraph = '', anchorHref, anchorText }) => {
  return (
    <div
      className={`flex flex-col justify-between px-4 py-5 rounded-lg shadow-lg ${className}`}
      style={{ minWidth: '220px', backgroundColor: 'rgba(255, 255, 255, 0.95)' }}
    >
      <div>
        <Icon className="mb-5 mt-4 block m-auto text-primary-500" fontSize="40px" name={iconName} />
        <div className="font-bold h-14 leading-7 text-center text-lg text-primary-800">{title}</div>
        <p className="leading-normal my-1 text-xs text-gray-800">{paragraph}</p>
      </div>
      {anchorText ? (
        <Link className="btn-text inline-flex justify-center h-12 leading-6 mt-3 text-secondary-600" to={anchorHref}>
          {anchorText}
        </Link>
      ) : null}
    </div>
  )
}

Poster.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
  title: PropTypes.string,
  paragraph: PropTypes.string,
  anchorHref: PropTypes.string,
  anchorText: PropTypes.string
}

export default Poster
